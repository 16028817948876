define([
        'modules/upx/models/model',
        './structs/Repair'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'id',
            object: 'Repair',
            newWithReturn: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newRepairWithReturn',parameters, ajaxOptions);
            },
            changeStatus: function (parameters, ajaxOptions) {
                return this.callObjectMethod('changeRepairStatus',parameters, ajaxOptions);
            },
            finalize: function (parameters, ajaxOptions) {
                return this.callObjectMethod('finalizeRepair',parameters, ajaxOptions);
            },
            getStats: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getRepairStats',parameters, ajaxOptions);
            }
        });
    }
);