define([
        'modules/upx/collections/collection',
        '../models/ShippingMethod',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ShippingModule',
            object: 'ShippingMethod',
            collection_method: 'listShippingMethods',
            model: Model
        });
    }
);