define([
        'modules/upx/models/model',
        './structs/ShippingMethod'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShippingModule',
            idAttribute: 'id',
            object: 'ShippingMethod'
        });
    }
);