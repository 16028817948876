define([
        'modules/upx/models/model',
        './structs/ShipmentItem'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShippingModule',

            object: 'ShipmentItem'
        });
    }
);