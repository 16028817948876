define([
        'modules/upx/models/model',
        './structs/ShopFlatProduct'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',

            object: 'ShopFlatProduct',
            naturalSearch: function (parameters, ajaxOptions) {
                return this.callObjectMethod('naturalSearchShopFlatProducts',parameters, ajaxOptions);
            },
            naturalSearchProductIds: function (parameters, ajaxOptions) {
                return this.callObjectMethod('naturalSearchShopFlatProductsProductIds',parameters, ajaxOptions);
            },
            naturalSearchForRelation: function (parameters, ajaxOptions) {
                return this.callObjectMethod('naturalSearchShopFlatProductsForRelation',parameters, ajaxOptions);
            },
            naturalSearchForCacheUpdate: function (parameters, ajaxOptions) {
                return this.callObjectMethod('naturalSearchShopFlatProductsForCacheUpdate',parameters, ajaxOptions);
            },
            naturalSearchAggregated: function (parameters, ajaxOptions) {
                return this.callObjectMethod('naturalSearchAggregatedShopFlatProducts',parameters, ajaxOptions);
            }
        });
    }
);