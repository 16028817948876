define([
        'modules/upx/models/model',
        './structs/Order'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'id',
            object: 'Order',
            getStatusPageUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getOrderStatusPageUrl',parameters, ajaxOptions);
            },
            setSpecialEuTaxRateOn: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setSpecialEuTaxRateOnOrder',parameters, ajaxOptions);
            },
            listIds: function (parameters, ajaxOptions) {
                return this.callObjectMethod('listOrderIds',parameters, ajaxOptions);
            },
            markAsCompleted: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderAsCompleted',parameters, ajaxOptions);
            },
            refundItems: function (parameters, ajaxOptions) {
                return this.callObjectMethod('refundOrderItems',parameters, ajaxOptions);
            },
            refundAllItems: function (parameters, ajaxOptions) {
                return this.callObjectMethod('refundAllOrderItems',parameters, ajaxOptions);
            },
            recalculateTotals: function (parameters, ajaxOptions) {
                return this.callObjectMethod('recalculateOrderTotals',parameters, ajaxOptions);
            },
            markAsRefunded: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderAsRefunded',parameters, ajaxOptions);
            },
            markAsOnHold: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderAsOnHold',parameters, ajaxOptions);
            },
            markAsProcessing: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderAsProcessing',parameters, ajaxOptions);
            },
            markOverpaidValueAsRefunded: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderOverpaidValueAsRefunded',parameters, ajaxOptions);
            },
            markAsCanceled: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderAsCanceled',parameters, ajaxOptions);
            },
            returnItemsToStock: function (parameters, ajaxOptions) {
                return this.callObjectMethod('returnOrderItemsToStock',parameters, ajaxOptions);
            },
            returnSelectedItemsToStock: function (parameters, ajaxOptions) {
                return this.callObjectMethod('returnSelectedOrderItemsToStock',parameters, ajaxOptions);
            },
            newFromCart: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newOrderFromCart',parameters, ajaxOptions);
            },
            markAsShipped: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderAsShipped',parameters, ajaxOptions);
            },
            markAsDelivered: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderAsDelivered',parameters, ajaxOptions);
            },
            markNegativeProductsAsDelivered: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderNegativeProductsAsDelivered',parameters, ajaxOptions);
            },
            markAsPaid: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markOrderAsPaid',parameters, ajaxOptions);
            },
            getPdfUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getOrderPdfUrl',parameters, ajaxOptions);
            },
            getPdfHtml: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getOrderPdfHtml',parameters, ajaxOptions);
            },
            updateStatus: function (parameters, ajaxOptions) {
                return this.callObjectMethod('updateOrderStatus',parameters, ajaxOptions);
            },
            newWithReturn: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newOrderWithReturn',parameters, ajaxOptions);
            },
            updateWithoutItems: function (parameters, ajaxOptions) {
                return this.callObjectMethod('updateOrderWithoutItems',parameters, ajaxOptions);
            },
            attachPaymentIdsTo: function (parameters, ajaxOptions) {
                return this.callObjectMethod('attachPaymentIdsToOrder',parameters, ajaxOptions);
            },
            newVolatile: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newVolatileOrder',parameters, ajaxOptions);
            },
            checkoutFromCart: function (parameters, ajaxOptions) {
                return this.callObjectMethod('checkoutFromCart',parameters, ajaxOptions);
            },
            newPaymentForNotPaidValue: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newPaymentForNotPaidOrderValue',parameters, ajaxOptions);
            },
            getShippingMethodCosts: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getOrderShippingMethodCosts',parameters, ajaxOptions);
            }
        });
    }
);