define([
        'modules/upx/collections/collection',
        '../models/RepairMessage',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ShopModule',
            object: 'RepairMessage',
            collection_method: 'listRepairMessages',
            model: Model
        });
    }
);