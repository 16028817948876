define([
        'modules/upx/models/model',
        './structs/Shipment'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShippingModule',
            idAttribute: 'id',
            object: 'Shipment',
            newParcelFor: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newParcelForShipment',parameters, ajaxOptions);
            },
            importParcelFor: function (parameters, ajaxOptions) {
                return this.callObjectMethod('importParcelForShipment',parameters, ajaxOptions);
            }
        });
    }
);